require("@rails/ujs").start()
require("turbolinks").start()

const $ = window.$ = window.jQuery = require("jquery");
window.I18n = require("i18n-js");

require("jquery-toast-plugin")
import "jquery-toast-plugin/dist/jquery.toast.min.css"

window.bootstrap = require("../student_v2/assets/bootstrap/js/index.esm")
import "../student_v2/assets/styles/student_v2.scss"

import "../student_v2/utils/NativeConfirm.js"
window.moment = require("moment");


import Application from "../student_v2/Application";

require('jquery-validation')

const select2 = require('select2');
global.select2 = select2;

window.application = new Application();

$(document).on('turbolinks:load', function(event) {

    $(document).on("submit", "form[method=get]", function(e) {
        Turbolinks.visit(this.action+(this.action.indexOf('?') === -1 ? '?' : '&')+$(this).serialize());
        return false;
    });

    Turbolinks.setProgressBarDelay(500);

    let $body = $("body");
    application.setController($body.data("controller"));
    application.setAction($body.data("action"));
    application.pageChanged();


    $(".copy_data").click(function (e){
        e.preventDefault();
        const el = document.createElement('textarea');
        el.value = $(this).data("copy");
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        let parent = $(this).parent();
        parent.data("content", I18n.t("common.copied"));
        parent.popover('show');
        setTimeout(function(){
            parent.popover('hide');
            parent.data("content", "");
        }, 2000);
        parent.on('hidden.bs.popover', function () {
            parent.popover('dispose');
        })
    })
});