export default class StudentsDashboardV2Elements {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $('.response-item').click(function() {
      $('.response-item').removeClass('active');
      $(this).addClass('active');

      var checkWrapper = $(this).find('.check-wrapper');

      $('.check-wrapper').empty();

      checkWrapper.append('<i class="mdi mdi-check text-primary fs-14 p-0 m-0"></i>');
    });

    const ratingBoxes = $('.rating-box');

    ratingBoxes.click(function() {
        ratingBoxes.removeClass('selected');
        $(this).addClass('selected');
    });
  }
}
